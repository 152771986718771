import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FaUserCircle, FaHome, FaWrench, FaRegCommentDots, FaPlusCircle, FaNotesMedical } from 'react-icons/fa';
import { BiReceipt, BiPhoneCall } from 'react-icons/bi';

function UserArticleFieldShow(props) {
  return (
    <Container className="UserArticleFieldShowContainer">
      <UserBox>
        <IconText>
          <FaUserCircle/>
          <a href={props.user_path}>#{props.user_id} {props.user_name}</a>
        </IconText>
      </UserBox>
      <UABox>
        <IconText>
          <FaHome/>
          <a href={props.article_path}>#{props.article_id} {props.article_name}</a>
        </IconText>
      </UABox>
      <ActionsBox>
        <span>
          <MessageButton>
            <a href={props.messages_path}>
              <FaRegCommentDots/>
              <em>トーク</em>
            </a>
          </MessageButton>
          { props.new_message_path &&
            <NewSubActionButton>
              <a href={props.new_message_path}>
                <FaPlusCircle></FaPlusCircle>
              </a>
            </NewSubActionButton>
          }
        </span>
        <span>
          <EstimateButton>
            <a href={props.estimates_path}>
              <FaWrench/>
              <em>見積もり</em>
            </a>
          </EstimateButton>
          { props.new_estimates_path &&
            <NewSubActionButton>
              <a href={props.new_estimates_path}>
                <FaPlusCircle></FaPlusCircle>
              </a>
            </NewSubActionButton>
          }
        </span>
        <span>
          <IHButton>
            <a href={props.interaction_histories_path}>
              <BiPhoneCall/>
              <em>応対履歴</em>
            </a>
          </IHButton>
          { props.new_interaction_history_path &&
            <NewSubActionButton>
              <a href={props.new_interaction_history_path}>
                <FaPlusCircle></FaPlusCircle>
              </a>
            </NewSubActionButton>
          }
        </span>
        <span>
          <IHButton>
            <a href={props.inspection_histories_path}>
              <FaNotesMedical/>
              <em>点検履歴</em>
            </a>
          </IHButton>
          { props.new_inspection_history_path &&
            <NewSubActionButton>
              <a href={props.new_inspection_history_path}>
                <FaPlusCircle></FaPlusCircle>
              </a>
            </NewSubActionButton>
          }
        </span>
        <PHButton>
          <a href={props.purchase_histories_path}>
            <BiReceipt/>
            <em>サービス利用履歴</em>
          </a>
        </PHButton>
      </ActionsBox>
    </Container>
  )
}

UserArticleFieldShow.propTypes = {
  id: PropTypes.number.isRequired,
  user_id: PropTypes.number.isRequired,
  user_name: PropTypes.string.isRequired,
  article_id: PropTypes.number.isRequired,
  article_name: PropTypes.string.isRequired,

  user_path: PropTypes.string.isRequired,
  article_path: PropTypes.string.isRequired,
  user_article_path: PropTypes.string.isRequired,
  messages_path: PropTypes.string,
  new_message_path: PropTypes.string,
  estimates_path: PropTypes.string,
  interaction_histories_path: PropTypes.string,
  new_interaction_history_path: PropTypes.string,
  inspection_histories_path: PropTypes.string,
  new_inspection_history_path: PropTypes.string,
  purchase_histories_path: PropTypes.string,
}

export default UserArticleFieldShow

const Container = styled.div`
  border: 1px solid #ccc;
  border-radius: 0.4em;
  padding: 1.5rem 1rem 0;
  background-color: #fafafa;
  svg {
    font-size: 1.4em;
  }
  display: inline-flex;
  flex-wrap: wrap;
  flex-direction: column;
  & > * {
    margin-bottom: 0.5rem;
  }
`

const IconText = styled.div`
  display: flex;
  svg, a {
    display: inline-block;
  }
  a {
    padding-left:  0.4em;
    padding-right: 0.4em;
  }
`

const UserBox = styled.div`
`

const UABox = styled.div`
  svg {
    color: #999;
  }
`
const ActionsBox = styled.div`
  flex: flex;
  margin-left: -1rem;
  margin-top: 0.5rem;
`
const ActionButton = styled.p`
  display: inline-block;
  border-radius: 2em;
  padding: 0;
  margin: 0;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  background-color: white;
  border: 1px solid #4C8FC3;
  a {
    display: flex;
    text-decoration: none;
    font-size: 0.8rem;
    color: #4C8FC3; //TODO: get $skyblue from _variables.scss
    padding: 0.4em 1em 0.2em;
    svg, em {
      display: inline-block;
    }
    &:hover {
      color: #94bcdb; //TODO: mix($white, $skyblue, 40%)
    }
  }
  &:hover {
    border-color: #94bcdb;
  }
  em {
    padding-left:  0.4em;
  }
`

const MessageButton = styled(ActionButton)``
const EstimateButton = styled(ActionButton)``
const IHButton = styled(ActionButton)``
const PHButton = styled(ActionButton)``

const SubActionButton = styled.span`
  svg {
    font-size: 1.2rem;
  }
`
const NewSubActionButton = styled(SubActionButton)`
  a {
    color: #60AB6A;
  }
`
