import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form, Field } from 'react-final-form';
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';

const SUCCESS_CREATE_TOKEN = '000';
const ERROR_MESSAGES = {
  emptyCardNumber: 'カード番号を入力してください。',
  invalidCardNumber: 'カード番号に誤りがあります。',
  emptyExpiryDate: '有効期限を入力してください。',
  monthOutOfRange: '有効期限の月に誤りがあります。',
  yearOutOfRange: '有効期限の年に誤りがあります。',
  dateOutOfRange: '有効期限の日に誤りがあります。',
  invalidExpiryDate: '有効期限に誤りがあります。',
  emptyCVC: 'セキュリティコードを入力してください。',
  invalidCVC: 'セキュリティコードに誤りがあります。'
}
const ERROR_CARD = {
  // GMO PaymentのテストカードにLuhnに通るものがないのでダミーカード番号で置換する
  // https://faq.gmo-pg.com/service/Detail.aspx?id=1680
  // https://pay.jp/docs/testcard
  '4000000000080319': {
    cardNumber: '4999000000000003',
    code: 'G03', 
    fullCode: '42G030000',
    memo: '限度額オーバー'
  }
}

function replaceTestCardNumber(inputCardNumber) {
  if ( ERROR_CARD[inputCardNumber] ) {
    return ERROR_CARD[inputCardNumber].cardNumber
  } else {
    return inputCardNumber
  }
}

function GmoPaymentCreditCardForm(props) {
  const [formError, setFormError] = useState(props.errorMessages);
  const [formDisabled, setFormDisabled] = useState(false);
  const [holderNameError, setHolderNameError] = useState('');
  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES
  });

  const execPurchase = (response) => {
    if (response.resultCode != SUCCESS_CREATE_TOKEN) {
      setFormDisabled(false);
      window.alert('購入処理中にエラーが発生しました');
      if (!tokenErrorMessage(response.resultCode)) return;
      setFormError([tokenErrorMessage(response.resultCode)]);
    } else {
      // カード情報は念のため値を除去
      document.getElementById('cardNumber').value = '';
      document.getElementById('expiryDate').value = '';
      document.getElementById('cvc').value = '';
      document.getElementById('holdername').value = '';
      // 予め購入フォームに用意した token フィールドに、値を設定
      // 発行されたトークンは、有効期限が経過するか、一度 API で利用されると、無効となります。
      // 複数のAPIでトークンを利用される場合は、tokenNumberにてトークンを複数発行してください。
      document.getElementById('token').value = response.tokenObject.token;
      // スクリプトからフォームを submit
      document.getElementById('purchaseForm').submit();
    }
  }

  const doPurchase = (formParams) => {
    Multipayment.init(`${props.gmoShopId}`);
    Multipayment.getToken({
      cardno : replaceTestCardNumber(formParams.cardNumber.replaceAll(' ', '')),
      expire : '20' + formParams.expiryDate.split(' / ').reverse().join(''), // YYYYMM
      securitycode : formParams.cvc,
      holdername : formParams.holdername,
      tokennumber : 1,
    }, response => execPurchase(response));
  }

  const tokenErrorMessage = (errorCode) => {
    const tokenErrorMessages = {
      '100': 'カード番号を入力してください。',
      '101': 'カード番号は数字のみを入力してください。',
      '102': 'カード番号の桁数に誤りがあります。',
      '110': '有効期限を入力してください。',
      '111': '有効期限は数字のみを入力してください。',
      '112': '有効期限の桁数に誤りがあります。',
      '113': '有効期限の月に誤りがあります。',
      '121': 'セキュリティコードは数字のみを入力してください。',
      '122': 'セキュリティコードの桁数に誤りがあります。',
      '131': 'カード名義人は半角英数字で入力してください。',
      '132': 'カード名義人は50文字以内で入力してください。',
      '141': '発行数は数字のみを入力してください。',
      '142': '発行数の桁数に誤りがあります。',
    }
    return tokenErrorMessages[errorCode];
  }

  return (
    <Container>
      <Form
        onSubmit={data => { setFormDisabled(true); doPurchase(data) }}
        // initialValues={{
        //   cardNumber: '4111 1111 1111 1111',
        //   expiryDate: '12 / 30',
        //   cvc: '111',
        //   holdername: 'John',
        // }}
        validate={() => {
          let errors = {};
          if (meta.erroredInputs.cardNumber) {
            errors.cardNumber = meta.erroredInputs.cardNumber;
          }
          if (meta.erroredInputs.expiryDate) {
            errors.expiryDate = meta.erroredInputs.expiryDate;
          }
          if (meta.erroredInputs.cvc) {
            errors.cvc = meta.erroredInputs.cvc;
          }
          return errors;
        }}
      >
        {({ handleSubmit, form, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            { (formError || meta.isTouched || !!holderNameError) && (
              <ErrorList>
                {formError && formError.map((e, i) => <li key={`formError-${i}`}>{e}</li>)}
                {meta.isTouched && meta.error && <li key="meta-error">{meta.error}</li>}
                {!!holderNameError && <li key="holdername-error">{holderNameError}</li>/*react-payment-inputsのmetaにholdernameが含まれない為、個別に追加*/}
              </ErrorList>
            )}
            <div>
              <Label>カード番号</Label>
              <CardNumberInputContainer>
                <SVG {...getCardImageProps({ images })} width="2.25em" height="1.5em" />
                <Field name="cardNumber">
                  {({ input }) => (
                    <CardNumberInput {...input} {...getCardNumberProps({ onBlur: input.onBlur, onChange: input.onChange })} placeholder="1234 5678 9123 4567" />
                  )}
                </Field>
              </CardNumberInputContainer>
            </div>
            <InputContainer>
                <Field name="expiryDate">
                  {({ input }) => (
                    <div>
                      <Label>有効期限</Label>
                      <Input left {...input} {...getExpiryDateProps({ onBlur: input.onBlur, onChange: input.onChange })} />
                    </div>
                  )}
                </Field>
                <Field name="cvc">
                  {({ input }) => (
                    <div>
                      <Label>セキュリティコード</Label>
                      <Input right {...input} {...getCVCProps({ onBlur: input.onBlur, onChange: input.onChange })} placeholder="999" />
                    </div>
                  )}
                </Field>
            </InputContainer>
            <Field
              name="holdername"
              component="input"
              type="text"
              validate={value => (value ? undefined : 'カード名義人を入力してください。')}
            >
              {({ input, meta }) => (
                <div>
                  <Label>カード名義人</Label>
                  <Input {...input} placeholder="TARO YAMADA" id="holdername" />
                  {
                    useEffect(() => {
                      (meta.error && meta.touched) ? setHolderNameError(meta.error) : setHolderNameError('')
                    }, [meta, setHolderNameError])
                  }
                </div>
              )}
            </Field>
            <ButtonContainer>
              <Button type="submit" disabled={submitting || formDisabled}>
                {(submitting || formDisabled) ? '支払い中...' : props.submitButtonText || '購入する'}
              </Button>
            </ButtonContainer>
          </form>
        )}
      </Form>
    </Container>
  );
}

export default GmoPaymentCreditCardForm

const Container = styled.div`
  font-size: 3.733333vw;
  padding: 4.5333vw;
`
const ErrorList = styled.ul`
  list-style: inside;
  list-style-type: disc;
  color: #ff5f5f;
  margin-bottom: 4.5333vw;
`
const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  padding-left: 4px;
  &:after {
    content: '※';
    display: inline-block;
    margin-left: 0.5em;
    color: #ff5f5f;
  }
`
const CardNumberInputContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  line-height: 9.0666666vw;
  margin-bottom: 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
`
const SVG = styled.svg`
  margin-left: 15px;
  vertical-align: middle;
`
const CardNumberInput = styled.input`
  padding: 0 15px;
  padding: 0 1.5rem;
`
const InputContainer = styled.div`
  display: flex;
  div {
    width: 50%;
  }
`
const Input = styled.input`
  width: 100%;
  font-size: 3.733333vw;
  box-sizing: border-box;
  line-height: 9.0666666vw;
  margin-bottom: 30px;
  padding: 0 15px;
  padding: 0 1.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  ${props => {
    if (props.left) {
      return `
        border-radius: 4px 0 0 4px;
        border-right: 1px solid #ddd;
      `
    }
    if (props.right) {
      return `
        border-left: none;
        border-radius: 0 4px 4px 0;
      `
    }
  }};
`
const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 0.5em;
`
const Button = styled.button`
  width: 100%;
  line-height: 10.666vw;
  text-align: center;
  font-size: 4.8vw;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  letter-spacing: .05em;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  transition: 0.4s;

  color: #fff;
  border-color: #0097dd;
  background-color: #0097dd;

  &:hover {
    color: #fff;
    background-color: #31b9f7;
    cursor: pointer;
  }
  &:disabled {
    color: #6c757d;
    background-color: #fff;
    border-color: #6c757d;
  }
`
const ResetButton = styled(Button)`
  color: #fff;
  border-color: #a2a2a2;
  background-color: #a2a2a2;
  margin-left: 3.333333vw;
  &:hover {
    color: #a2a2a2;
    background-color: transparent;
  }
  &:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
  }
`
