import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SVG from "react-inlinesvg";
import styled from "styled-components";
import { searchLinesApi, searchStationsApi } from "./api";

import ArrowIcon from "../../packs/users/img/icons/ArrowRight.svg";

import * as Yup from "yup";
const schema = Yup.object().shape({
  name: Yup.string().when("building_type", {
    is: "mansion",
    then: (schema) => schema.required("必須項目です"),
    otherwise: (schema) => schema.notRequired(),
  }),
  address: Yup.string().trim().required("必須項目です"),
  address_town: Yup.string().trim().required("必須項目です"),
  building_type: Yup.string(),
  floor: Yup.string().when("building_type", {
    is: "mansion",
    then: (schema) => schema.required("必須項目です"),
    otherwise: (schema) => schema.notRequired(),
  }),
  floor_area: Yup.string().when("building_type", {
    is: "mansion",
    then: (schema) => schema.required("必須項目です"),
    otherwise: (schema) => schema.notRequired(),
  }),
  built_date: Yup.string()
    .when("building_type", {
      is: "land",
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required("必須項目です"),
    })
    .transform((date) => {
      return `${date}-01`;
    }),
  room_type: Yup.string().when("building_type", {
    is: "mansion",
    then: (schema) => schema.required("必須項目です"),
    otherwise: (schema) => schema.notRequired(),
  }),
  land_size: Yup.string().when("building_type", {
    is: "mansion",
    then: (schema) => schema.notRequired(),
    otherwise: (schema) => schema.required("必須項目です"),
  }),
  building_floor_size: Yup.string().when("building_type", {
    is: "detached_house",
    then: (schema) => schema.required("必須項目です"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

function AiAssessmentForm(props) {
  console.log("🚀 ~ HelloApp ~ props:", props);

  const [errorMessages, setErrorMessages] = useState([]);
  const [nameFocus, setNameFocus] = useState(false);
  const [articles, setArticles] = useState([]);
  const [isFetch, setIsFetch] = useState(false);
  const [openSetback, setOpen] = useState(false);
  const [openConnectingRoadStatus, setOpen2] = useState(false);
  const [builtDate, setBuiltDate] = useState();
  const [isFetchTrainLine, setIsFetchTrainLine] = useState(false);

  const onClick = () => setOpen((prev) => !prev);
  const onClick2 = () => setOpen2((prev) => !prev);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    setFocus,
    clearErrors,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      article_id: props.article?.id || null,
      name: props.aiAssessment?.name || "",
      building_type: props.aiAssessment?.building_type || "",
      // zip: props.aiAssessment?.zip || "",
      prefecture: props.aiAssessment?.prefecture || "",
      address: props.aiAssessment?.address || "",
      address_town: props.aiAssessment?.address_town || "",
      address_street: props.aiAssessment?.address_street || "",
      room: props.aiAssessment?.room || "",
      built_date: props.aiAssessment?.built_date
        ? moment(props.aiAssessment?.built_date).format("YYYY-MM")
        : "",
      room_type: props.aiAssessment?.room_type || "",
      floor_area: props.aiAssessment?.floor_area,
      floor: props.aiAssessment?.floor,
      building_floor_size: props.aiAssessment?.building_floor_size,
      architectural_structure:
        props.aiAssessment?.architectural_structure || "",
      land_size: props.aiAssessment?.land_size,
      land_rights: props.aiAssessment?.land_rights || "",
      building_coverage_ratio: props.aiAssessment?.building_coverage_ratio,
      floor_area_ratio: props.aiAssessment?.floor_area_ratio,
      total_unit_count: props.aiAssessment?.total_unit_count,
      total_story: props.aiAssessment?.total_story,
      train_line_id: props.aiAssessment?.train_line_id || "",
      train_line: props.aiAssessment?.train_line || "",
      station_id: props.aiAssessment?.station_id || "",
      station: props.aiAssessment?.station || "",
      walking_time: props.aiAssessment?.walking_time,
      travel_time_by_bus: props.aiAssessment?.travel_time_by_bus || "",
      walking_time_from_bus_stop:
        props.aiAssessment?.walking_time_from_bus_stop || "",
      setback_type: props.aiAssessment?.setback_type || "",
      setback_distance: props.aiAssessment?.setback_distance,
      setback_size: props.aiAssessment?.setback_size,
      connecting_road_status: props.aiAssessment?.connecting_road_status || "",
      connecting_road_pavement:
        props.aiAssessment?.connecting_road_pavement || "",
      connecting_road_type_1:
        props.connectingRoads?.length > 0
          ? props.connectingRoads[0].connecting_road_type
          : "",
      connecting_road_1: props.connectingRoads?.first?.connecting_road || "",
      connecting_road_width_1:
        props.connectingRoads?.length > 0
          ? props.connectingRoads[0].connecting_road_width
          : "",
      connecting_road_direction_1:
        props.connectingRoads?.length > 0
          ? props.connectingRoads[0].connecting_road_direction
          : "",
      connecting_road_type_2:
        props.connectingRoads?.length > 1
          ? props.connectingRoads[1].connecting_road_type
          : "",
      connecting_road_2:
        props.connectingRoads?.length > 1
          ? props.connectingRoads[1].connecting_road
          : "",
      connecting_road_width_2:
        props.connectingRoads?.length > 1
          ? props.connectingRoads[1].connecting_road_width
          : "",
      connecting_road_direction_2:
        props.connectingRoads?.length > 1
          ? props.connectingRoads[1].connecting_road_direction
          : "",
      connecting_road_type_3:
        props.connectingRoads?.length > 2
          ? props.connectingRoads[2].connecting_road_type
          : "",
      connecting_road_3:
        props.connectingRoads?.length > 2
          ? props.connectingRoads[2].connecting_road
          : "",
      connecting_road_width_3:
        props.connectingRoads?.length > 2
          ? props.connectingRoads[2].connecting_road_width
          : "",
      connecting_road_direction_3:
        props.connectingRoads?.length > 2
          ? props.connectingRoads[2].connecting_road_direction
          : "",
      connecting_road_type_4:
        props.connectingRoads?.length > 3
          ? props.connectingRoads[3].connecting_road_type
          : "",
      connecting_road_4:
        props.connectingRoads?.length > 3
          ? props.connectingRoads[3].connecting_road
          : "",
      connecting_road_width_4:
        props.connectingRoads?.length > 3
          ? props.connectingRoads[3].connecting_road_width
          : "",
      connecting_road_direction_4:
        props.connectingRoads?.length > 3
          ? props.connectingRoads[3].connecting_road_direction
          : "",
    },
  });

  const prefecture = watch("prefecture");
  const train_line_id = watch("train_line_id");
  const [lines, setLines] = useState([]);
  const [stations, setStations] = useState([]);

  useEffect(() => {
    if (prefecture) {
      const getLines = async () => {
        const response = await searchLinesApi(getCSRFtoken(), prefecture);
        const json = await response.json();
        const lines = await json.response[0].lines;
        if (lines) {
          setLines(lines);
        }
      };
      getLines();
    }
  }, [prefecture]);

  useEffect(() => {
    setTimeout(() => {
      setValue("train_line_id", props.aiAssessment?.train_line_id);
    }, [2000]);
  }, []);

  useEffect(() => {
    if (train_line_id) {
      const getStations = async () => {
        await setIsFetchTrainLine(true);
        const response = await searchStationsApi(getCSRFtoken(), train_line_id);
        const json = await response.json();
        const stations = await json.response[0].stations;
        if (stations) {
          await setStations(stations);
        }
        await setIsFetchTrainLine(false);
      };

      getStations();
    }
  }, [train_line_id]);

  useEffect(() => {
    setTimeout(() => {
      setValue("station_id", props.aiAssessment?.station_id);
    }, [4000]);
  }, []);

  const handleBuildingTypeErrorClear = (newValue) => {
    if (newValue === "land") {
      clearErrors("built_date");
    }
    if (["detached_house", "land"].includes(newValue)) {
      clearErrors("name");
      clearErrors("room_type");
      clearErrors("floor");
    }
    if (["mansion", "land"].includes(newValue)) {
      clearErrors("building_floor_size");
    }
  };

  const handleWalkingByBus = (value) => {
    const travel_time_by_bus = watch("travel_time_by_bus");
    if (!!value && !!travel_time_by_bus) {
      clearErrors("walking_time");
    }
  };

  const handleTravelTimeByBus = (value) => {
    const walking_time_from_bus_stop = watch("walking_time_from_bus_stop");
    if (!!walking_time_from_bus_stop && !!value) {
      clearErrors("walking_time");
    }
  };

  const findPrefectureCode = (prefectureName) => {
    return props.prefectures.find(
      (prefecture) => prefecture[0] === prefectureName
    )[1];
  };

  const selectArticle = (article) => {
    setValue("name", article.name, { shouldValidate: true });
    setValue("prefecture", findPrefectureCode(article.address.pref), {
      shouldValidate: true,
    });
    setValue("address", article.address.city, { shouldValidate: true });
    setValue("address_town", article.address.town, { shouldValidate: true });
    setValue("address_street", article.address.street, {
      shouldValidate: true,
    });

    if (article.builtDate) {
      setBuiltDate(article.builtDate);

      const builtDate = moment(article.builtDate).format("YYYY-MM");
      setValue("built_date", builtDate, { shouldValidate: true });
    }
    setFocus("name");

    setArticles([]);
  };

  const name = watch("name");

  const searchApi = () => {
    const url = "/users/ai_assessments/search_mansions";
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        authenticity_token: getCSRFtoken(),
        ai_assessment: { name },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((body) => {
        console.log("🚀 ~ onSubmit ~ body:", body);
        if (body.status === "success") {
          console.log("🚀 success");
          setArticles(body.response.mansions);
          setNameFocus(false);
        } else {
          setErrorMessages(body.error);
          window.scrollTo(0, 0);
        }
      })
      .catch((err) => {
        console.err("🚀 ~ onSubmit ~ err:", err);
        return err;
      });
  };

  useEffect(() => {
    if (nameFocus && name.length >= 2) {
      searchApi();
    }
    if (name.length < 2) {
      setArticles([]);
    }
  }, [name, nameFocus]);

  useEffect(() => {
    if (props.aiAssessment?.built_date) {
      setBuiltDate(props.aiAssessment?.built_date);
    }
  }, [props.aiAssessment?.built_date]);

  const connecting_road_type_1 = watch("connecting_road_type_1");

  const [selectedBuildingType, setSelectedBuildingType] = useState(
    props.aiAssessment?.building_type || ""
  );
  const handleBuildingTypeChange = (e) => {
    setSelectedBuildingType(e.target.value);
    handleBuildingTypeErrorClear(e.target.value);
  };
  const [selectedConnectingRoadStatus, setSelectedConnectingRoadStatus] =
    useState(props.aiAssessment?.connecting_road_status || "");
  const handleConnectingRoadStatus = (e) => {
    setSelectedConnectingRoadStatus(e.target.value);
  };

  const getCSRFtoken = () => $("meta[name=csrf-token]").attr("content");
  const onSubmit = (data) => {
    console.log(data);
    setErrorMessages([]);
    if (isFetch) return;

    setIsFetch(true);
    const url = "/users/ai_assessments/create_ai_assessment";
    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        authenticity_token: getCSRFtoken(),
        ai_assessment: {
          ...data,
          built_date: builtDate ? builtDate : data.built_date,
        },
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((body) => {
        console.log("🚀 ~ onSubmit ~ body:", body);
        if (body.status === "success") {
          window.location.href = `/users/ai_assessments/${body.id}`;
        } else {
          setErrorMessages(body.error);
          window.scrollTo(0, 0);
        }
        setIsFetch(false);
      })
      .catch((err) => {
        console.err("🚀 ~ onSubmit ~ err:", err);
        return err;
      });
  };

  return (
    <FormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errorMessages &&
          errorMessages.map((errorMessage) => (
            <p key={`err1-${errorMessage}`} className="error-message card">
              {errorMessage}
            </p>
          ))}

        <div className="form-row separator">
          <label>
            物件タイプ<span>必須</span>
          </label>

          <div className="item-select">
            <select
              {...register("building_type")}
              value={selectedBuildingType}
              onChange={handleBuildingTypeChange}
            >
              {props.buildingTypes.map((buildingType) => (
                <option
                  value={buildingType[1]}
                  key={`buildingType-${buildingType[1]}`}
                >
                  {buildingType[0]}
                </option>
              ))}
            </select>
          </div>
        </div>

        {selectedBuildingType === "mansion" && (
          <div className="form-row">
            <label>
              物件名<span>必須</span>
            </label>

            <input
              type="text"
              placeholder="AI査定マンション"
              {...register("name")}
              className={errors.name && "input-error"}
              onKeyDown={(e) => {
                if (selectedBuildingType !== "mansion") return;
                if (["Enter", "Backspace"].includes(e.key)) {
                  setNameFocus(true);
                }
              }}
              onBlur={() => setNameFocus(false)}
            ></input>
            {errors.name && (
              <p className="error-message">{errors.name.message}</p>
            )}

            {articles.length !== 0 && (
              <div className="article-list">
                {articles?.map((article) => (
                  <div
                    className="article-list-item"
                    key={`article-${article.id}`}
                    onClick={() => selectArticle(article)}
                  >
                    <div className="item-name">{article.name}</div>
                    <div className="item-address">
                      {article.address.pref}
                      {article.address.city}
                      {article.address.town}
                      {article.address.street}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        {/* <div className="form-row">
          <label>郵便番号</label>
          <input
            type="text"
            placeholder="0000000"
            className={errors.zip && "input-error"}
            {...register("zip")}
          ></input>
          {errors.zip && <p className="error-message">{errors.zip.message}</p>}
        </div> */}

        <div className="form-row">
          <label>
            都道府県<span>必須</span>
          </label>
          <div className="item-select">
            <select {...register("prefecture")}>
              {props.prefectures.map((prefecture) => (
                <option
                  value={prefecture[1]}
                  key={`prefecture-${prefecture[1]}`}
                >
                  {prefecture[0]}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="form-row">
          <label>
            市区町村
            <span>必須</span>
          </label>
          <input
            type="text"
            placeholder="千代田区"
            className={errors.address && "input-error"}
            {...register("address")}
          ></input>
          {errors.address && (
            <p className="error-message">{errors.address.message}</p>
          )}
        </div>

        <div className="form-row">
          <label>
            町丁目<span>必須</span>
          </label>
          <input
            type="text"
            placeholder="外神田１丁目"
            className={errors.address_town && "input-error"}
            {...register("address_town")}
          ></input>
          {props.aiAssessment.name && (
            <p className="caption">
              町丁目の内容が自動入力されている場合は、町丁目と番地号がそれぞれ正しく入力されているかご確認ください。（正しい町丁目入力例：外神田１丁目）
            </p>
          )}
          {errors.address_town && (
            <p className="error-message">{errors.address_town.message}</p>
          )}
        </div>

        <div className="form-row">
          <label>番地号</label>
          <input
            type="text"
            placeholder="１番１０号"
            className={errors.address_street && "input-error"}
            {...register("address_street")}
          ></input>
          <p className="caption">
            番地情報を入力ください。
            <br />
            この入力がない場合、交通情報の入力が必要になります。
          </p>
          {errors.address_street && (
            <p className="error-message">{errors.address_street.message}</p>
          )}
        </div>

        {selectedBuildingType === "mansion" && (
          <div className="form-inner">
            <div className="form-row half">
              <label>部屋番号</label>
              <input
                type="text"
                placeholder="101"
                className={errors.room && "input-error"}
                {...register("room")}
              ></input>
              {errors.room && (
                <p className="error-message">{errors.room.message}</p>
              )}
            </div>

            <div className="form-row half">
              <label>
                築年月
                {selectedBuildingType !== "land" && <span>必須</span>}
              </label>
              <input
                type="month"
                className={errors.built_date && "input-error"}
                {...register("built_date")}
                onChange={() => setBuiltDate(null)}
              ></input>
              {errors.built_date && (
                <p className="error-message">{errors.built_date.message}</p>
              )}
            </div>

            <div className="form-row half">
              <label>
                間取り
                {selectedBuildingType === "mansion" && <span>必須</span>}
              </label>

              <div className="item-select">
                <select
                  className={errors.room_type && "input-error"}
                  {...register("room_type")}
                >
                  <option value=""></option>
                  {props.roomTypes.map((roomType) => (
                    <option value={roomType[1]} key={`roomType-${roomType[1]}`}>
                      {roomType[0]}
                    </option>
                  ))}
                </select>
              </div>
              <p className="caption">近い間取りで構いません</p>
            </div>

            <div className="form-row half unit">
              <label>
                専有面積<span>必須</span>
              </label>
              <input
                type="number"
                placeholder="70"
                className={errors.floor_area && "input-error"}
                {...register("floor_area")}
              ></input>
              <span className="unit">m²</span>
              <p className="caption">おおよその面積で構いません</p>
              {errors.floor_area && (
                <p className="error-message">{errors.floor_area.message}</p>
              )}
            </div>

            <div className="form-row half unit">
              <label>
                所在階
                {selectedBuildingType === "mansion" && <span>必須</span>}
              </label>
              <input
                type="number"
                placeholder="2"
                className={errors.floor && "input-error"}
                {...register("floor")}
              ></input>
              <span className="unit">階</span>
              {errors.floor && (
                <p className="error-message">{errors.floor.message}</p>
              )}
            </div>

            <div className="form-row half">
              <label>向き</label>

              <div className="item-select">
                <select {...register("direction")}>
                  <option value=""></option>
                  {props.directions.map((direction) => (
                    <option
                      value={direction[1]}
                      key={`direction-${direction[1]}`}
                    >
                      {direction[0]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row half unit">
              <label>総戸数</label>
              <input
                type="number"
                placeholder="2"
                className={errors.total_unit_count && "input-error"}
                min="1"
                max="10000"
                {...register("total_unit_count")}
              ></input>
              <span className="unit">戸</span>
              {errors.total_unit_count && (
                <p className="error-message">
                  {errors.total_unit_count.message}
                </p>
              )}
            </div>

            <div className="form-row half unit">
              <label>建物階数</label>
              <input
                type="number"
                placeholder="2"
                className={errors.total_story && "input-error"}
                min="1"
                max="10000"
                {...register("total_story")}
              ></input>
              <span className="unit">階建て</span>
              {errors.total_story && (
                <p className="error-message">{errors.total_story.message}</p>
              )}
            </div>
          </div>
        )}

        {selectedBuildingType === "detached_house" && (
          <div className="form-inner">
            <div className="form-row half">
              <label>
                築年月<span>必須</span>
              </label>
              <input
                type="month"
                className={errors.built_date && "input-error"}
                {...register("built_date")}
                onChange={() => setBuiltDate(null)}
              ></input>
              {errors.built_date && (
                <p className="error-message">{errors.built_date.message}</p>
              )}
            </div>

            <div className="form-row half unit">
              <label>
                延床面積<span>必須</span>
              </label>
              <input
                type="number"
                placeholder="100"
                className={errors.building_floor_size && "input-error"}
                {...register("building_floor_size")}
              ></input>
              <span className="unit">m²</span>
              {errors.building_floor_size && (
                <p className="error-message">
                  {errors.building_floor_size.message}
                </p>
              )}
            </div>

            <div className="form-row half unit">
              <label>建物階数</label>
              <input
                type="number"
                placeholder="2"
                className={errors.total_story && "input-error"}
                {...register("total_story")}
              ></input>
              <span className="unit">階建て</span>
              {errors.total_story && (
                <p className="error-message">{errors.total_story.message}</p>
              )}
            </div>

            <div className="form-row half">
              <label>間取り</label>

              <div className="item-select">
                <select
                  className={errors.room_type && "input-error"}
                  {...register("room_type")}
                >
                  <option value=""></option>
                  {props.roomTypes.map((roomType) => (
                    <option value={roomType[1]} key={`roomType-${roomType[1]}`}>
                      {roomType[0]}
                    </option>
                  ))}
                </select>
              </div>
              <p className="caption">近い間取りで構いません</p>
            </div>

            <div className="form-row">
              <label>建物構造</label>

              <div className="item-select">
                <select
                  className={errors.architectural_structure && "input-error"}
                  {...register("architectural_structure")}
                >
                  <option value=""></option>
                  {props.architecturalStructures.map(
                    (architecturalStructure) => (
                      <option
                        value={architecturalStructure[1]}
                        key={`roomType-${architecturalStructure[1]}`}
                      >
                        {architecturalStructure[0]}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
          </div>
        )}

        {selectedBuildingType !== "mansion" && (
          <div className="form-inner">
            <div className="form-row half unit">
              <label>
                土地面積<span>必須</span>
              </label>
              <input
                type="number"
                placeholder="100"
                className={errors.land_size && "input-error"}
                {...register("land_size")}
              ></input>
              <span className="unit">m²</span>
              {errors.land_size && (
                <p className="error-message">{errors.land_size.message}</p>
              )}
            </div>

            <div className="form-row half">
              <label>土地権利</label>

              <div className="item-select">
                <select {...register("land_rights")}>
                  <option value=""></option>
                  {props.landRights.map((landRight) => (
                    <option
                      value={landRight[1]}
                      key={`direction-${landRight[1]}`}
                    >
                      {landRight[0]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row">
              <label>用途地域</label>

              <div className="item-select">
                <select {...register("use_district")}>
                  <option value=""></option>
                  {props.useDistricts.map((useDistrict) => (
                    <option
                      value={useDistrict[1]}
                      key={`direction-${useDistrict[1]}`}
                    >
                      {useDistrict[0]}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-row half unit">
              <label>建蔽率</label>
              <input
                type="number"
                placeholder="60"
                className={errors.building_coverage_ratio && "input-error"}
                {...register("building_coverage_ratio")}
              ></input>
              <span className="unit">%</span>
              {errors.building_coverage_ratio && (
                <p className="error-message">
                  {errors.building_coverage_ratio.message}
                </p>
              )}
            </div>

            <div className="form-row half unit">
              <label>容積率</label>
              <input
                type="number"
                placeholder="300"
                className={errors.floor_area_ratio && "input-error"}
                {...register("floor_area_ratio")}
              ></input>
              <span className="unit">%</span>
              {errors.floor_area_ratio && (
                <p className="error-message">
                  {errors.floor_area_ratio.message}
                </p>
              )}
            </div>
          </div>
        )}

        <h2>交通情報</h2>

        <div className="help">
          住所の番地情報を入力していない場合、交通情報の入力が必須となります。
        </div>

        <div className="form-row">
          <label>最寄りの路線名</label>

          <div className="item-select">
            <select
              {...register("train_line_id")}
              onChange={(e) => {
                setValue("train_line_id", e.target.value);
                setValue("train_line", e.target.selectedOptions[0].text);
              }}
            >
              <option value=""></option>
              {lines.map((line) => (
                <option key={`line-${line.id}`} value={line.id}>
                  {line.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* <div className="form-row">
          <label>最寄りの路線名</label>
          <input
            type="text"
            placeholder="JR山手線"
            className={errors.train_line && "input-error"}
            {...register("train_line")}
          ></input>
        </div> */}

        <div className="form-row">
          <label>最寄りの駅名</label>

          <div className="item-select">
            <select
              {...register("station_id")}
              onChange={(e) => {
                setValue("station_id", e.target.value);
                setValue("station", e.target.selectedOptions[0].text);
              }}
            >
              <option value=""></option>
              {stations.map((station) => (
                <option key={`station_-${station.id}`} value={station.id}>
                  {station.name}
                </option>
              ))}
            </select>
          </div>
          {isFetchTrainLine && <p>読み込み中です...</p>}
        </div>

        {/* <div className="form-row">
          <label>最寄りの駅名</label>
          <input
            type="text"
            placeholder="神田駅(東京都)"
            className={errors.station && "input-error"}
            {...register("station")}
          ></input>
        </div> */}

        <div className="form-row half unit">
          <label>駅からの徒歩</label>
          <input
            type="number"
            placeholder="5"
            className={errors.walking_time && "input-error"}
            {...register("walking_time")}
          ></input>
          <span className="unit">分</span>
          {/*{errors.walking_time && (*/}
          {/*    <p className="error-message">{errors.walking_time.message}</p>*/}
          {/*)}*/}
        </div>

        <div className="help">
          バスでの移動が必要な場合は、バスでの移動でかかる時間、バスの到着地から更に徒歩でかかる時間を入力ください。
        </div>

        <div className="form-row half unit">
          <label>バスでの移動</label>
          <input
            type="number"
            placeholder="5"
            className={errors.travel_time_by_bus && "input-error"}
            {...register("travel_time_by_bus")}
            onChange={handleTravelTimeByBus}
          ></input>
          <span className="unit">分</span>
          {errors.travel_time_by_bus && (
            <p className="error-message">{errors.travel_time_by_bus.message}</p>
          )}
        </div>

        <div className="form-row half unit">
          <label>バス停からの徒歩</label>
          <input
            type="number"
            placeholder="5"
            className={errors.walking_time_from_bus_stop && "input-error"}
            {...register("walking_time_from_bus_stop")}
            onChange={handleWalkingByBus}
          ></input>
          <span className="unit">分</span>
          {errors.walking_time_from_bus_stop && (
            <p className="error-message">
              {errors.walking_time_from_bus_stop.message}
            </p>
          )}
        </div>

        {selectedBuildingType !== "mansion" && (
          <div>
            <h2>セットバック</h2>

            <div className="help info">
              <b>おすすめ！</b>
              セットバックは任意の入力項目ですが、入力いただくことでより正確な査定が可能になります。
            </div>

            <div
              className={`form-inner collapse ${
                openSetback ? "visible" : "hidden"
              }`}
            >
              <div className="form-row">
                <label>セットバック区分</label>
                <div className="item-select">
                  <select {...register("setback_type")}>
                    {props.setbackTypes.map((setbackType) => (
                      <option
                        value={setbackType[1]}
                        key={`setbackType-${setbackType[1]}`}
                      >
                        {setbackType[0]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="form-row half unit">
                <label>距離</label>
                <input
                  type="number"
                  placeholder="5"
                  className={errors.setback_distance && "input-error"}
                  {...register("setback_distance")}
                ></input>
                <span className="unit">m</span>
              </div>

              <div className="form-row half unit">
                <label>面積</label>
                <input
                  type="number"
                  placeholder="5"
                  className={errors.setback_size && "input-error"}
                  {...register("setback_size")}
                ></input>
                <span className="unit">m²</span>
              </div>
            </div>

            <button type="button" className="collapse-toggle" onClick={onClick}>
              {openSetback
                ? "セットバック入力枠を閉じる"
                : "セットバックを入力する"}
            </button>

            <h2>接道情報</h2>

            <div className="help info">
              <b>おすすめ！</b>
              接道情報は任意の入力項目ですが、入力いただくことでより正確な査定が可能になります。
            </div>

            <div
              className={`form-inner collapse ${
                openConnectingRoadStatus ? "visible" : "hidden"
              }`}
            >
              <div className="form-row half">
                <label>接道情報</label>
                <div className="item-select">
                  <select
                    {...register("connecting_road_status")}
                    value={selectedConnectingRoadStatus}
                    onChange={handleConnectingRoadStatus}
                  >
                    {props.connectingRoadStatuses.map(
                      (connectingRoadStatus) => (
                        <option
                          value={connectingRoadStatus[1]}
                          key={`buildingType-${connectingRoadStatus[1]}`}
                        >
                          {connectingRoadStatus[0]}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>

              <div className="form-row half">
                <label>舗装</label>
                <div className="item-select">
                  <select {...register("connecting_road_pavement")}>
                    {props.connectingRoadPavements.map(
                      (connectingRoadPavement) => (
                        <option
                          value={connectingRoadPavement[1]}
                          key={`connectingRoadPavement-${connectingRoadPavement[1]}`}
                        >
                          {connectingRoadPavement[0]}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>

              {selectedConnectingRoadStatus &&
                selectedConnectingRoadStatus !== "no_road" && (
                  <div className="form-inner">
                    <h2>接道情報-1</h2>

                    <div className="form-row half">
                      <label>
                        接道種別<span>必須</span>
                      </label>
                      <div className="item-select">
                        <select {...register("connecting_road_type_1")}>
                          {props.connectingRoadTypes.map(
                            (connectingRoadType) => (
                              <option
                                value={connectingRoadType[1]}
                                key={`connectingRoadType-${connectingRoadType[1]}`}
                              >
                                {connectingRoadType[0]}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="form-row half">
                      <label>接面</label>
                      <input
                        type="number"
                        placeholder="5"
                        className={errors.connecting_road && "input-error"}
                        {...register("connecting_road_1")}
                      ></input>
                    </div>

                    <div className="form-row half">
                      <label>方向</label>

                      <div className="item-select">
                        <select {...register("connecting_road_direction_1")}>
                          <option value=""></option>
                          {props.connectingRoadDirections.map((direction) => (
                            <option
                              value={direction[1]}
                              key={`direction-${direction[1]}`}
                            >
                              {direction[0]}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="form-row half unit">
                      <label>幅員</label>
                      <input
                        type="number"
                        placeholder="5"
                        className={
                          errors.connecting_road_width_1 && "input-error"
                        }
                        {...register("connecting_road_width_1")}
                      ></input>
                      <span className="unit">m</span>
                    </div>
                  </div>
                )}

              {(selectedConnectingRoadStatus === "two_sides" ||
                selectedConnectingRoadStatus === "three_sides" ||
                selectedConnectingRoadStatus === "four_sides" ||
                selectedConnectingRoadStatus === "corner") && (
                <div className="form-inner">
                  <h2>接道情報-2</h2>
                  <div className="form-row half">
                    <label>
                      接道種別<span>必須</span>
                    </label>
                    <div className="item-select">
                      <select {...register("connecting_road_type_2")}>
                        {props.connectingRoadTypes.map((connectingRoadType) => (
                          <option
                            value={connectingRoadType[1]}
                            key={`connectingRoadType-${connectingRoadType[1]}`}
                          >
                            {connectingRoadType[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-row half">
                    <label>接面</label>
                    <input
                      type="number"
                      placeholder="5"
                      className={errors.connecting_road_2 && "input-error"}
                      {...register("connecting_road_2")}
                    ></input>
                  </div>

                  <div className="form-row half">
                    <label>方向</label>

                    <div className="item-select">
                      <select {...register("connecting_road_direction_2")}>
                        <option value=""></option>
                        {props.connectingRoadDirections.map((direction) => (
                          <option
                            value={direction[1]}
                            key={`direction-${direction[1]}`}
                          >
                            {direction[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-row half unit">
                    <label>幅員</label>
                    <input
                      type="number"
                      placeholder="5"
                      className={
                        errors.connecting_road_width_2 && "input-error"
                      }
                      {...register("connecting_road_width_2")}
                    ></input>
                    <span className="unit">m</span>
                  </div>
                </div>
              )}

              {(selectedConnectingRoadStatus === "three_sides" ||
                selectedConnectingRoadStatus === "four_sides") && (
                <div className="form-inner">
                  <h2>接道情報-3</h2>
                  <div className="form-row half">
                    <label>
                      接道種別<span>必須</span>
                    </label>
                    <div className="item-select">
                      <select {...register("connecting_road_type_3")}>
                        {props.connectingRoadTypes.map((connectingRoadType) => (
                          <option
                            value={connectingRoadType[1]}
                            key={`connectingRoadType-${connectingRoadType[1]}`}
                          >
                            {connectingRoadType[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-row half">
                    <label>接面</label>
                    <input
                      type="number"
                      placeholder="5"
                      className={errors.connecting_road_3 && "input-error"}
                      {...register("connecting_road_3")}
                    ></input>
                  </div>

                  <div className="form-row half">
                    <label>方向</label>

                    <div className="item-select">
                      <select {...register("connecting_road_direction_3")}>
                        <option value=""></option>
                        {props.connectingRoadDirections.map((direction) => (
                          <option
                            value={direction[1]}
                            key={`direction-${direction[1]}`}
                          >
                            {direction[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-row half unit">
                    <label>幅員</label>
                    <input
                      type="number"
                      placeholder="5"
                      className={
                        errors.connecting_road_width_3 && "input-error"
                      }
                      {...register("connecting_road_width_3")}
                    ></input>
                    <span className="unit">m</span>
                  </div>
                </div>
              )}

              {selectedConnectingRoadStatus === "four_sides" && (
                <div className="form-inner">
                  <h2>接道情報-4</h2>
                  <div className="form-row half">
                    <label>
                      接道種別<span>必須</span>
                    </label>
                    <div className="item-select">
                      <select {...register("connecting_road_type_4")}>
                        {props.connectingRoadTypes.map((connectingRoadType) => (
                          <option
                            value={connectingRoadType[1]}
                            key={`connectingRoadType-${connectingRoadType[1]}`}
                          >
                            {connectingRoadType[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-row half">
                    <label>接面</label>
                    <input
                      type="number"
                      placeholder="5"
                      className={errors.connecting_road_4 && "input-error"}
                      {...register("connecting_road_4")}
                    ></input>
                  </div>

                  <div className="form-row half">
                    <label>方向</label>

                    <div className="item-select">
                      <select {...register("connecting_road_direction_4")}>
                        <option value=""></option>
                        {props.connectingRoadDirections.map((direction) => (
                          <option
                            value={direction[1]}
                            key={`direction-${direction[1]}`}
                          >
                            {direction[0]}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-row half unit">
                    <label>幅員</label>
                    <input
                      type="number"
                      placeholder="5"
                      className={
                        errors.connecting_road_width_4 && "input-error"
                      }
                      {...register("connecting_road_width_4")}
                    ></input>
                    <span className="unit">m</span>
                  </div>
                </div>
              )}
            </div>

            <button
              type="button"
              className="collapse-toggle"
              onClick={onClick2}
            >
              {openConnectingRoadStatus
                ? "接道情報入力枠を閉じる"
                : "接道情報を入力する"}
            </button>
          </div>
        )}

        <div className="button-container">
          <div className="monthly-limit-display">
            今月はあと
            <span
              className={
                "limit-count " + (props.remainingNum === 0 ? "limit" : "")
              }
            >
              {props.remainingNum}
            </span>
            回AI無料査定が可能です
          </div>

          {errorMessages &&
            errorMessages.map((errorMessage) => (
              <div key={`err1-${errorMessage}`} className="error-message card">
                {errorMessage}
              </div>
            ))}

          <button
            type="submit"
            // className={`btn btn-primary ${
            // !isValid || isFetch ? "disabled" : ""
            // }`}
            // disabled={isFetch || !isValid}
            className={`btn btn-primary ${!isValid ? "disabled" : ""}`}
            disabled={!isValid}
          >
            {isFetch && <div className="loading" />}
            {isFetch && <>査定中...</>}
            {!isFetch && <>査定開始！</>}
            <SVG className="arrow" src={ArrowIcon} />
          </button>
        </div>
      </form>
    </FormContainer>
  );
}

export default AiAssessmentForm;

const FormContainer = styled.div`
  padding: 0 1.6rem 3.2rem;

  input[type="text"],
  input[type="date"],
  input[type="month"],
  input[type="number"] {
    width: 100%;
    line-height: 2.4rem;
    padding: 0.8rem;
    color: #0a0a0a;
    font-size: 1.6rem;
    border: solid 1px #ddd;
    border-radius: 0.4rem;
    outline: none;
    appearance: none;
    box-sizing: border-box;

    &::-webkit-datetime-edit {
      display: block;
      padding: 0;
    }

    &.input-error {
      background: #FCECEC;
      border-color: #E24545;
    }
  }

  input[type="date"],
  input[type="month"]
  {
    height: 4.2rem;
  }

  .item-select {
    position: relative;
    select {
      width: 100%;
      padding: 0 1rem;
      font-size: 1.6rem;
      border: solid 1px #ddd;
      border-radius: 0.4rem;
      line-height: 4rem;
      color: #0a0a0a;
      outline: none;
      appearance: none;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      right: 0.8rem;
      bottom: 0;
      width: 0.1rem;
      height: 1rem;
      border-style: solid;
      border-width: 0.7rem 0.5rem;
    }
    &:before {
      top: 10%;
      border-color: transparent transparent #777;
    }
    &:after {
      bottom: 10%;
      border-color: #777 transparent transparent;
    }
  }

  input::placeholder {
    color: #ddd;
  }

  h2 {
    width: 100%;
    padding-top: 1.6rem;
    margin-bottom: 1.2rem;
    color: #556;
    font-size: 1.4rem;
    font-weight: 600;
    border-top: 1px dashed #ddd;
  }

  .collapse-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.4rem auto 2.4rem;
    color: #556;
    font-size: 1.4rem;
    text-decoration: underline;
    outline: 0;
  }

  > form,
  .form-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.collapse {
      overflow: hidden;
    }
    
    &.visible {
      height: auto;
    }
    
    &.hidden {
      height: 0;
    }
  }

  .article-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    z-index: 3000;
    .article-list-item {
      padding: 1.2rem;
      color: $black;
      font-size: 1.4rem;
      border-bottom: 1px solid #ddd;
      &:last-child {
        border: 0;
      }
      .item-address {
        font-size: 1.2rem;
      }
    }
  }

  .form-row {
    position: relative;
    width: 100%;
    margin-bottom: 2.4rem;
    &.separator {
      padding-bottom: 2.4rem;
      border-bottom: 1px dashed #ddd;
    }
    &.half {
      width: calc(50% - 0.8rem);
    }
    &.unit {
      position relative;
      input[type="text"],
      input[type="date"],
      input[type="month"],
      input[type="number"] {
        padding-right: 4rem;
      }    
      span.unit {
        position: absolute;
        top: 3.6rem;
        right: 1rem;
        color: #556;
        font-size: 1.3rem;
      }
    }
    label {
      display: flex;
      align-items: center;
      line-height: 1.8rem;
      margin-bottom: 0.8rem;
      font-size: 1.3rem;
      span {
        margin-left: 0.8rem;
        padding: 0.4rem 0.8rem;
        line-height: 1rem;
        color: white;
        font-size: 1rem;
        font-weight: 600;
        background-color: #59504d;
        border-radius: 0.4rem;
      }
    }
    .caption {
      margin-top: 0.4rem;
      line-height: 1.6rem;
      font-size: 1.1rem;
    }
  }

  .help {
    line-height: 1.8rem;
    padding: 0.8rem;
    margin-bottom: 1.6rem;
    font-size: 1.2rem;
    background-color: #f5f5f5;
    border-radius: 0.4rem;
    &.info {
      background-color: #E5F5FC;
      b {
        color: #0097DD;
      }
    }
  }

  .button-container {
    width: 100%;
    padding: 0;
    border-top: 1px dashed #ddd;
    .monthly-limit-display {
      margin: 3.2rem 0 1.2rem;
    }
    .btn {
      justify-content: center;
      width: 100%;
      padding: 1.6rem 0.8rem;
      color: white;
      border: 1px solid #0097dd;
      background-color: #0097dd;
      &:hover,
      &:focus {
        color: white;
        border: 1px solid #0097dd;
        background-color: #31b9f7;
      }
      &.disabled {
        color: #a2a2a2;
        background-color: #ddd;
        border-color: #ccc;
      }
    }
  }
  .error-message {
    margin-top: 0.4rem;
    line-height: 1.6rem;
    color: #e24545;
    font-size: 1.2rem;
    &.card {
      width: 100%;
      margin: 0 0 1rem;
      padding: 0.8rem;
      text-align: center;
      background-color: #FCECEC;
      border-radius: 0.4rem;
    }
  }
  .loading {
    position: absolute;
    top: 1.8rem;
    left: 1.2rem;

    width: 2rem;
    padding: 0.4rem;
    aspect-ratio: 1;
    border-radius: 50%;
    background: white;
      --_m: 
        conic-gradient(#0000 10%,#000),
        linear-gradient(#000 0 0) content-box;
      -webkit-mask: var(--_m);
              mask: var(--_m);
      -webkit-mask-composite: source-out;
              mask-composite: subtract;
      animation: l3 1s infinite linear;
    }
  @keyframes l3 {to{transform: rotate(1turn)}}
`;
