import React, { useEffect, useState, useRef, useCallback } from 'react'
import { MessageList, Popup, Input, Button } from 'react-chat-elements';
import AutoLinkText from 'react-autolink-text2';
import {useDropzone} from 'react-dropzone'

import 'react-chat-elements/dist/main.css';
import styled from 'styled-components'
import Frisbee from 'frisbee'

import { FaAlignJustify, FaChevronDown, FaChevronUp, FaCloudUploadAlt } from 'react-icons/fa';

function FileUploadButton(props) {
  const onDrop = useCallback(props.onDrop, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept: "image/jpeg,image/png,.xls,.xlsx,.doc,.docx,.pdf,.pptx"})

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <FaCloudUploadAlt className="rce-input-button-left"/>
    </div>
  )
}

const api = new Frisbee({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

const postApi = new Frisbee({
  headers: {
    credentials: "include"
  },
})

function App(props) {
  const [messages, setMessages] = useState([]);
  const [recentId, setRecentId] = useState(props.message.id);
  const [pastId, setPastId] = useState(props.message.id + 1);
  const [hasRecentMessage, setHasRecentMessage] = useState(true);
  const [hasPastMessage, setHasPastMessage] = useState(false);
  const [sendButtonDisabled, setSendButtonDisabled] = useState(false);
  const inputRef = useRef(null);
  const recentButtonRef = useRef(null);

  useEffect(() => {
    getPastMessages()
  }, [])

  const convert = (response) => {
    const data = response.map((d) => {
      const type = typeOptions(d);
      const allowBusinessUserLink = () => 
        !props.isMobile && d.sender_url && (props.isAdmin || d.sender_id === props.currentUserId);
      return {
        id: d.id,
        position: d.message_type === 'user' ? 'left' : 'right',
        status: d.message_type === 'user' ? ( d.admin_read ? null : null) : ( d.read ? 'read' : null),
        date: new Date(d.created_at),
        dateString: d.created_at_local,
        title: d.message_type === 'user' ? null : (allowBusinessUserLink() ? <a href={d.sender_url} target="_blank">{d.display_sender_name}</a> : d.display_sender_name),
        ...type,
      }
    })
    return data;
  }

  const typeOptions = (data) => {
    if ( data.file_url ) {
      return {
        type: 'file',
        text: data.file_filename,
        data: {
          size: data.file_size_text,
          uri: data.file_url,
          status: {
            download: true,
          }
        }
      }
    } else if ( data.image_url ) {
      return {
        type: 'photo',
        text: data.image_filename,
        data: {
          uri: data.image_url,
          status: {
            download: true,
          }
        }
      }
    } else {
      return {
        type: 'text',
        text: ( <AutoLinkText text={data.body}/> ),
      }
    }
  }

  const getPastMessages = () => {
    api
      .get(props.urls.chat + `?q[search_by_user_query]=${props.user_article.user_id}&q[id_lt]=${pastId}`)
      .then(response => {
        if ( response.body.messages.length !== 0 ) {
          setHasPastMessage(true)
          return response
        } else {
          setHasPastMessage(false)
          return Promise.reject('finished')
        }
      })
      .then(response => {
        setPastId(response.body.messages.sort((a,b)=>a.id - 0 - b.id)[0].id -0)
        return response
      })
      .then(response => {
        return convert(response.body.messages)
      })
      .then(data => {
        return setMessages([...data, ...messages])
      })
      .catch(err => {
        if (err !== 'finished'){
          console.error(err)
        }
      })
  }

  const getRecentMessages = () => {
    api
      .get(props.urls.chat + `?q[search_by_user_query]=${props.user_article.user_id}&q[id_gt]=${recentId}`)
      .then(response => {
        if ( response.body.messages.length !== 0 ) {
          // setHasRecentMessage(true)
          return response
        } else {
          // setHasRecentMessage(false)
          return Promise.reject('finished')
        }
      })
      .then(response => {
        if ( props.isMobile === true  ) {
          const id = response.body.messages.sort((a,b)=>b.id - 0 - a.id)[0].id -0
          setRecentId(id)
          return response
        } else {
          const id = response.body.messages.sort((a,b)=>b.id - 0 - a.id)[0].id -0
          setRecentId(id)
          window.history.replaceState(null, null, props.urls.show + '/' + id)
          return response
        }
      })
      .then(response => {
        return convert(response.body.messages.reverse())
      })
      .then(data => {
        return setMessages([...messages, ...data])
      })
      .catch(err => {
        if (err !== 'finished'){
          console.error(err)
        }
      })
  }

  const postMessage = (event) => {
    let message = inputRef.current.input.value;
    if (!message) { return; }
    setSendButtonDisabled(true)
    // message = JSON.stringify( message )//escape special characters
    const form = new FormData()
    form.append("user_article_id", props.user_article.id)
    form.append("body", message)
    postApi
      .post(props.urls.chat, { body: form })
      .then(res => res.body)
      .then(() => { getRecentMessages() })
      .then(() => { inputRef.current.clear() } )
      .finally(() => { setSendButtonDisabled(false) } )
  }

  const onDropFileUploadButton = (acceptedFiles) => {
    const file = acceptedFiles[0]
    if ( !file ) { return false }
    const form = new FormData()
    form.append("user_article_id", props.user_article.id)
    if ( file.type.match(/^image\//) ) {
      form.append("image", file, file.name)
    } else {
      form.append("file", file, file.name)
    }
    postApi
      .post(props.urls.chat, { body: form })
      .then(res => res.body)
      // .then(() => { getRecentMessages() })
      .then(() => { recentButtonRef.current.click() } )
  }

  const onOpen = (data) => {
    if ( data.type === 'file' ) {
      const link = document.createElement('a')
      link.href = data.data.uri
      if ( data.type === 'photo' ){
        link.target = '_blank'
      }
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

    } else if ( data.type === 'photo' ) {
      if ( !data.text ) {
        return true; // 過去ログ分はデータが不足しているため無視する
      }
      const link = document.createElement('a')
      link.href = data.data.uri
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  return (
    <Container className={props.isMobile ? "container-mobile" : ''}>
      {( !props.isMobile &&
        <div className="btn-open-close">
          <FaAlignJustify/>
        </div>
      )}
      <Header>
        <header>
          <h1>{props.user_article.user.name}</h1>
          <p>{props.user_article.article.name}</p>
        </header>
      </Header>
      <Contents>
        <MessageList
          // toBottomHeight={'100%'}
          dataSource={messages}
          downButton={false}
          onOpen={onOpen} />
        {( hasPastMessage &&
          <div className='rce-mlist-down-button rce-mlist-up-button' onClick={getPastMessages}><FaChevronUp/></div>
        )}
        {( hasRecentMessage &&
          <div className='rce-mlist-down-button' ref={recentButtonRef} onClick={getRecentMessages}><FaChevronDown/></div>
        )}
        </Contents>
      <Tools>
        <Input
          placeholder="送信ボタンで送信します"
          multiline={true}
          ref={inputRef}
          rightButtons={
            <Button
              color='white'
              backgroundColor='#3979aa'
              text='送信'
              disabled={sendButtonDisabled}
              onClick={postMessage}/>}
          leftButtons={<FileUploadButton onDrop={onDropFileUploadButton}/>}
        />
      </Tools>
    </Container>
  )
}

export default App

const Container = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 0 14px 0 rgb(0 0 0 / 12%), 0 6px 6px rgb(0 0 0 / 20%);
  margin: calc(1.5em / 1.5);
  overflow: hidden;

  position: fixed;
  right: 0;
  bottom: 0;
  width: 50vw;
  max-width: 640px;
  height: 80vh;

  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;

  &.container-mobile {
    left: 0;
    top: 1em;
    width: calc(100vw - 2em);
    height: calc(100svh - 3em);
    h1 {
      font-size: 1.1rem;
    }
    p {
      font-size: 0.9rem;
    }
  }

  .btn-open-close {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 40px;
    height: 40px;
    background: #fff;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.05), 0 2px 5px 0 rgba(0,0,0,0.1);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
    transition: 200ms;
    &:hover {
      opacity: 0.7;
    }
  }
`

const Header = styled.div`
  background-color: #35cce6;
  color: white;

  padding: 0 32px;
  text-align: center;

  h1 {
    font-size: 1.6rem;
    margin: 0.5em auto;
  }
`

const Contents = styled.div`
  background-color: #fafafa;

  flex: 1;
  height: 50vh;
  position: relative;

  .rce-container-mlist {
    height: 100%;
  }
  .rce-mlist {
    overscroll-behavior: contain;
  }
  .rce-container-mbox {
    margin: 0.5em 0;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .rce-mbox {
    max-width: 70%;
  }
  .rce-mbox-body {
    padding-bottom: 12px;
  }
  .rce-mbox-text {
    white-space: pre-wrap;
  }
  .rce-mbox-photo {
    padding-bottom: 13px;
    img {
      cursor: pointer;
    }
    img[src$=image] {
      cursor: default;
    }
    .rce-mbox-text {
      padding: 5px 6px;
      max-width: none;
      margin: auto;
    }
  }
  .rce-mbox-file {
    button {
      color: rgba(0, 0, 0, .45);
    }
    color: rgba(0, 0, 0, .45);
  }
  .rce-mbox-time-block {
    background: none;
    color: rgba(0, 0, 0, .45);
  }
  .rce-mlist-up-button {
    top: 15px;
  }
`

const Tools = styled.div`
  min-height: 1em;
  max-height: 10em;
  overflow: hidden;
  padding: 0 20px;
  .rce-container-input {
    margin: 5px 0;
  }
  .rce-button {
    padding-right: 20px;
    padding-left: 20px;
  }

  background-color: #f4f7f9;
  textarea, .rce-container-input {
    background-color: transparent;
  }
  .rce-button {
    font-weight: normal;
  }
  .rce-input-button-left {
    font-size: 1.4em;
  }
`
