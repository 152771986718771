import React from 'react'
import Frisbee from 'frisbee'
// import SVG, { Props as SVGProps } from 'react-inlinesvg'

import HomeIcon         from '../packs/users/img/footer/fa-home-solid.svg'
import MessageIcon      from '../packs/users/img/footer/fa-comment-dots-solid.svg'
import NotificationIcon from '../packs/users/img/footer/fa-bell-solid.svg'
import PhoneIcon        from '../packs/users/img/footer/fa-phone-alt-solid.svg'

import styled from 'styled-components'

export default class Footer extends React.Component {
  state = {
    noticeCount: 0,
  }
  componentDidMount() {
    this.getBadgeCount()
  }
  getBadgeCount() {
    const api = new Frisbee({
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    api
      .get(`/users/badge_count`)
      .then(response => {
        this.setState({ noticeCount: response.body.news })
        this.setState({ messageNoticeCount: response.body.message })
      })
      .catch(err => {
        this.setState({ noticeCount: 0 })
        this.setState({ messageNoticeCount: 0})
        console.error(err)
      })
  }
  renderIcon({ icon, path, badgeCount, label, className='', dataTarget }) {
    const badge = badgeCount > 9 ? (<BadgeMore>9+</BadgeMore>) : (<Badge>{badgeCount}</Badge>);
    return (
      <FooterImage href={path} className={className} data-target={dataTarget}>
        <FooterImageIcon src={icon} />
        {badgeCount > 0 ? badge : null}
        <FooterImageLabel>{label}</FooterImageLabel>
      </FooterImage>
    )
  }
  render() {
    const { users_message_path, root_path, users_news_index_path, using_message, using_but_closed_message } = this.props
    return (
      <Container>
        {this.renderIcon({
          icon: HomeIcon,
          path: root_path,
          badgeCount: 0,
          label: 'ホーム'
        })}
        {this.renderIcon({
          icon: PhoneIcon,
          path: "#",
          badgeCount: 0,
          label: 'お問い合わせ',
          className: 'js-modal-open',
          dataTarget: 'inquiry-modal-box'
        })}
        {using_message && this.renderIcon({
          icon: MessageIcon,
          path: users_message_path,
          badgeCount: this.state.messageNoticeCount,
          label: 'トーク'
        })}
        {using_but_closed_message && this.renderIcon({
          icon: MessageIcon,
          path: null,
          badgeCount: null,
          label: 'トーク',
          className: 'js-modal-open',
          dataTarget: 'message-closed-modal-box'
        })}
        {this.renderIcon({
          icon: NotificationIcon,
          badgeCount: this.state.noticeCount,
          path: users_news_index_path,
          label: 'お知らせ'
        })}
      </Container>
    )
  }
}

const FooterImageIcon = styled.img`
  display: inline-block;
  width: 100%;
  height: 3rem;
`

const FooterImageLabel = styled.span`
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #556;
`

const FooterImage = styled.a`
  width: calc(100% / 4);
  height: inherit;
  text-align: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 16px;
`

const Badge = styled.div`
  background-color: red;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  color: white;
  margin: 2px;
  font-size: 9px;
  right: 25%;
  text-align: center;
  font-weight: bold;
`

const BadgeMore = styled.div`
  background-color: red;
  position: absolute;
  width: 20px;
  height: 16px;
  border-radius: 16px;
  color: white;
  margin: 2px;
  font-size: 9px;
  right: 25%;
  text-align: center;
  font-weight: bold;
`

const Container = styled.div`
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-around;
  border-top: 1px solid #ccc;
`
