import React, { useState, useEffect, useMemo, createRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { groupBy } from 'lodash'

const getArticles = (props, query, setArticles, setMeta, articlePage) => {
  const authorizedScope = (props.owner.type === 'Organization') ? `q[organization_id_eq]=${props.owner.id}&` : ''
  return fetch(props.articles_path + `?` + authorizedScope + query.replace(/\n/g,"&") + `&page=${articlePage}`)
    .then(res => res.json())
    .then(body => {
      if ( 'articles' in body ) {
        return body
      } else {
        return Promise.reject()
      }
    })
    .then(body => {
      if ( body.articles.length !== 0 ) {
        return body
      } else {
        return []
      }
    })
    .then(body => {
      setArticles(body.articles)
      if (body.meta ) setMeta(body.meta.page)
      return
    })
    .catch(err => {
      console.error(err)
    })
}

function ArticlePagination(props) {
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState()
  const [next, setNext] = useState(false)
  const [prev, setPrev] = useState(false)
  const [first, setFirst] = useState(false)
  const [last, setLast] = useState(false)

  useEffect(()=>{
    if ( props.meta ) {
      setPage(props.meta.current_page)
      setPages(props.meta.total_pages)
      setNext(props.meta.current_page < props.meta.total_pages)
      setPrev(props.meta.current_page > 1)
      setFirst(true)
      setLast(true)
    }
  }
  ,[props.meta])

  const handleClick = e => {
    e.preventDefault()
    const page = e.target.dataset.page
    props.onPageChange(page, props.checkedArticles)
  }

  return (
    <ArticlePaginationContainer>
      {pages > 0 && (
      <div className="pagination">
        <ul>{
          first ? <li><a href="#" data-page={1} onClick={handleClick}>&laquo; 最初</a></li> : null
        }{
          prev && (page - 1 > 0) ? <li><a href="#" data-page={page - 1} onClick={handleClick}>&lsaquo; 前へ</a></li> : null
        }
        {
          // display '...' if page is more than current_page - 4
          page > 5 ? <li>...</li> : null
        }
        {
          Array.from({length: pages}, (_, k) => k + 1).map((v, k) => {
            if ( v === page ) {
              return <li key={k} className="active"><span>{v}</span></li>
            } else if ( v < page - 4 || v > page + 4 ) {
              return null
            } else if ( v <= pages ) {
              return <li key={k}><a href="#" data-page={v} onClick={handleClick}>{v}</a></li>
            }
          })
        }
        {
          // display '...' if page is less than current_page + 4
          page < pages - 4 ? <li>...</li> : null
        }
        {
          next && ( page < pages) ? <li><a href="#" data-page={page + 1} onClick={handleClick}>次へ &rsaquo;</a></li> : null
        }{
          last ? <li><a href="#" data-page={pages} onClick={handleClick}>最後 &raquo;</a></li> : null
        }</ul>
      </div>
      )}
    </ArticlePaginationContainer>
  )
}

function ArticleCollectionTable(props) {
  const handleClick = e => {
    if (e.target.tagName === 'TD') {
      const input = e.target.closest('tr').querySelector("input[type=checkbox]")
      if ( input ) {
        input.click()
      }
    }
  }

  if ( !props.query ){
    return (
      <ArticleCollectionContainer>
        <p>何か条件を指定してください</p>
      </ArticleCollectionContainer>
    )
  }

  if ( props.articles && props.articles.length === 0 ){
    return (
      <ArticleCollectionContainer>
        <p>条件に一致する物件がありません</p>
      </ArticleCollectionContainer>
    )
  }

  return (
    <ArticleCollectionContainer>
      <table>
        <thead><tr>
          <th></th>
          <th>ID</th>
          <th>物件名</th>
          <th>都道府県</th>
          <th>竣工日</th>
          <th>戸数</th>
        </tr></thead>
        <tbody>{
        props.articles && props.articles.map( (article) => {
          return (
            <tr key={`article_${article.id}`} onClick={handleClick}>
              <th><input type="checkbox"
                    data-id={article.id}
                    onChange={props.handleChange}
                    checked={props.checkedItems.includes(""+article.id) || false}/></th>
              <td>{article.id}</td>
              <td>{article.name}</td>
              <td>{article.prefecture_name}</td>
              <td>{article.completion_date}</td>
              <td>{article.unit}</td>
            </tr>
          )
        })
      }</tbody></table>
    </ArticleCollectionContainer>
  )

}

function PrefectureTable(props) {
  const prefectures = groupBy(props.prefecture_areas.prefectures, 2)

  return (
    <table><tbody>{
      props.prefecture_areas.areas.map( (area) => {
        return (
          <tr key={`area_${area[0]}`}>
            <th>{area[1]}</th>
            <td>{
              prefectures[area[1]].map( (pref) => {
                return (
                  <label key={`pref_${pref[1]}`}>
                    <input type="checkbox" value={pref[1]}
                      data-id={pref[1]}
                      onChange={props.handleChange}
                      checked={props.checkedItems[pref[1]] || false}
                    />{pref[0]}
                  </label>)
              })
            }</td>
          </tr>
        )
      })
    }</tbody></table>
  )
}

function NewsSegmentParamsFieldForm(props) {
  const [articles, setArticles] = useState([])
  const [meta, setMeta] = useState({})
  const [articlePage, setArticlePage] = useState(1)
  const useInput = initialValue => {
    const [value, set] = useState(initialValue)
    return { value, onChange: (e) => { set(e.target.value); } }
  }
  const zipStartAny = useInput((props.q.zip_start_any || []).join("\n"))
  const completionYearEq = useInput(props.q.completion_year_eq || "")
  const buildingAgeGteq = useInput(props.q.building_age_gteq || "")
  const buildingAgeLteq = useInput(props.q.building_age_lteq || "")
  const unitGteq = useInput(props.q.unit_gteq || "")
  const unitLteq = useInput(props.q.unit_lteq || "")

  const initialAreas = (props.q.prefecture_in || []).reduce((acc, value, index) => {
    acc[`${value}`] = true;
    return acc;
  }, {})
  const [checkedAreas, setCheckedAreas] = useState(initialAreas)
  const handleCheckedAreasChange = e => {
    e.stopPropagation();
    setCheckedAreas({
      ...checkedAreas,
      [e.target.dataset.id]: e.target.checked
    })
  }

  // get all ids of the articles checked in all pages
  const ids = []
  if ( props.q.id_in ) {
    props.q.id_in.map( (value) => {
      ids.push(value)
    })
  }
  const [allPagesCheckedArticles, setAllPagesCheckedArticles] = useState(ids)

  const handleCheckedArticlesChange = e => {
    e.stopPropagation();
    if (e.target.checked) {
      setAllPagesCheckedArticles([...allPagesCheckedArticles, e.target.dataset.id])
    } else {
      setAllPagesCheckedArticles(allPagesCheckedArticles.filter(item => item !== e.target.dataset.id))
    }
  }
  
  const conditionResultValue = useMemo(() => {
    const result = []
    if ( Object.keys(checkedAreas).length > 0 ){
      Object.keys(checkedAreas).map( (value, index) => {
        if ( checkedAreas[value] === true ){
          result.push( "q[prefecture_in][]=" + value )
        }
      });
    }
    if ( zipStartAny.value.split("\n").length > 0 ) {
      zipStartAny.value.split("\n").map( (value, index) => {
        result.push( "q[zip_start_any][]=" + value )
      })
    }
    if ( completionYearEq.value && completionYearEq.value - 0 > 0 ){
      result.push( "q[completion_year_eq]=" + completionYearEq.value )
    }
    if ( buildingAgeGteq.value && buildingAgeGteq.value - 0 > 0 ){
      result.push( "q[building_age_gteq]=" + buildingAgeGteq.value )
    }
    if ( buildingAgeLteq.value && buildingAgeLteq.value - 0 > 0 ){
      result.push( "q[building_age_lteq]=" + buildingAgeLteq.value )
    }
    if ( unitGteq.value && unitGteq.value - 0 > 0 ){
      result.push( "q[unit_gteq]=" + unitGteq.value )
    }
    if ( unitLteq.value && unitLteq.value - 0 > 0 ){
      result.push( "q[unit_lteq]=" + unitLteq.value )
    }
    return result.join("\n")
  },[
    checkedAreas,
    zipStartAny.value,
    completionYearEq.value,
    buildingAgeGteq.value, buildingAgeLteq.value,
    unitGteq.value, unitLteq.value,
  ])

  useEffect(()=>{
    if ( conditionResultValue ) {
      getArticles(props, conditionResultValue, setArticles, setMeta, articlePage)
    }
  },[conditionResultValue, articlePage])

  const articleResultValue = useMemo(() => {
    const result = []
    // check if articles is undefined
    if (!articles) {
      return ""
    } else {
      allPagesCheckedArticles.map( (article) => {
        result.push( "q[id_in][]=" + article )
      })
      return result.join("\n")
    }
  }, [articles, allPagesCheckedArticles])

  const resultValue = useMemo(() => {
    return conditionResultValue + "\n" + articleResultValue
  },[conditionResultValue, articleResultValue])

  const handlePageChange = (page, currentCheckedArticles) => {
    setArticlePage(page)
    setAllPagesCheckedArticles(currentCheckedArticles)
  }

  return (
    <Container className="NewsSegmentParamsFieldFormContainer">
      <ResultFieldUnit>
        <LabelBox>
          物件検索条件
        </LabelBox>
        <FieldBox>
          <textarea name={props.name} value={resultValue} readOnly={true}></textarea>
        </FieldBox>
      </ResultFieldUnit>

      <FieldUnit>
        <LabelBox>
          エリア
          <LabelHint>未選択：全エリア対象</LabelHint>
        </LabelBox>
        <PrefectureFieldBox>
          <PrefectureTable {...props} checkedItems={checkedAreas} handleChange={handleCheckedAreasChange}/>
        </PrefectureFieldBox>
      </FieldUnit>

      <FieldUnit>
        <LabelBox>
          郵便番号エリア
          <LabelHint>前方一致・改行区切り</LabelHint>
        </LabelBox>
        <ZipFieldBox>
          <textarea {...zipStartAny}></textarea>
        </ZipFieldBox>
      </FieldUnit>

      <FieldUnit>
        <LabelBox>
          竣工年
        </LabelBox>
        <FieldBox>
          <label><input type="number" placeholder="YYYY" min="1950" {...completionYearEq}/>年</label>
        </FieldBox>
      </FieldUnit>

      <FieldUnit>
        <LabelBox>
          築年数
        </LabelBox>
        <NumberRangeFieldBox>
          <label><input type="number" min={0} {...buildingAgeGteq}/>年以上</label>
          <label><input type="number" min={0} {...buildingAgeLteq}/>年以下</label>
        </NumberRangeFieldBox>
      </FieldUnit>

      <FieldUnit>
        <LabelBox>
          戸数
        </LabelBox>
        <NumberRangeFieldBox>
          <label><input type="number" min={0} {...unitGteq}/>戸以上</label>
          <label><input type="number" min={0} {...unitLteq}/>戸以下</label>
        </NumberRangeFieldBox>
      </FieldUnit>

      <FieldUnit>
        <LabelBox>
          物件選択
          <LabelHint>未選択：全物件対象</LabelHint>
        </LabelBox>
        <FieldBox>
          <ArticleCollectionTable
            query={conditionResultValue}
            articles={articles}
            meta={meta}
            checkedItems={allPagesCheckedArticles}
            handleChange={handleCheckedArticlesChange}/>
          <ArticlePagination meta={meta} onPageChange={handlePageChange} checkedArticles={allPagesCheckedArticles}/>
        </FieldBox>
      </FieldUnit>
      
    </Container>
  )
}

NewsSegmentParamsFieldForm.propTypes = {
  prefecture_areas: PropTypes.object,
  q: PropTypes.object,
  name: PropTypes.string.isRequired,
  owner: PropTypes.object,
}

export default NewsSegmentParamsFieldForm

const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  > div:last-child {
    margin-bottom: 0;
  }
`
const FieldUnit = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-bottom: 1.5em;
`
const ResultFieldUnit = styled(FieldUnit)`
  display: none;
`
const LabelBox = styled.div`
  flex-grow: 1;
  width: calc(15% - 1rem);
  margin-left: 1rem;
  text-align: right;
  font-weight: bold;
`
const LabelHint = styled.p`
  font-size: 0.8em;
  font-weight: normal;
  color: #999;
`
const FieldBox = styled.div`
  flex-grow: 1;
  margin-left: 2rem;
  width: 100%;
  max-width: 50rem;
  label {
    display: inline-block;
    margin-right: 2em;
    font-weight: normal;
  }
  input[type=number] {
    width: 10em;
    display: inline-block;
    margin-right: 0.5em;
  }
`
const PrefectureFieldBox = styled(FieldBox)`
  table {
    th {
      font-weight: normal;
      white-space: nowrap;
    }
    td {
      display: flex;
      flex-flow: row wrap;
      input {
        display: inline-block;
      }
      label {
        font-weight: normal;
        display: inline-block;
        padding-right: 1em;
        cursor: pointer;
      }
    }
  }
`
const NumberRangeFieldBox = styled(FieldBox)`
`
const ZipFieldBox = styled(FieldBox)`
  textarea {
    width: 20em;
    min-height: 10em;
    display: inline-block;
  }
`

const ArticleCollectionContainer = styled.div`
  table {
    tbody th {
      width: 4em;
      text-align: center;
    }
  }
`

const ArticlePaginationContainer = styled.div`
  .pagination {
    margin: 1em 0 1.5em;
    ul {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      list-style: none;
      padding: 0;
      li {
        margin: 0 0.4em;
        a,
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 30px;
          height: 30px;
          padding: 3px 6px;
          margin: 0;
          color: #293f54;
          text-decoration: none;
          border-radius: 3px;
          background-color: white;
          border: 1px solid #adb5bd;
          transition: all 0.2s;
          outline: 0;
          &:hover {
            color: #4C8FC3;
            border-color: #4C8FC3;
          }
        }
      }
      li.active {
        font-weight: bold;
        span {
          color: white;
          background-color: #4C8FC3;
          border-color: #4C8FC3;
        }
      }
    }
  }
`
