export const searchLinesApi = (token, prefId) => {
  return fetch("/users/ai_assessments/search_lines", {
    method: "POST",
    body: JSON.stringify({
      authenticity_token: token,
      ai_assessment: { pref_id: prefId },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export const searchStationsApi = (token, trainLineId) => {
  return fetch("/users/ai_assessments/search_stations", {
    method: "POST",
    body: JSON.stringify({
      authenticity_token: token,
      ai_assessment: { train_line_id: trainLineId },
    }),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
