import * as FilePond from "filepond";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

//スライドの設定
$(function(){
  if ($('#js--slide').length) {
    $('#js--slide').slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: false,
      adaptiveHeight: true
    });
    $('#js--slide').removeClass('loading');
  }
  if ($('.detail--slide').length) {
    $('.detail--slide').slick({
      dots: true,
      arrows: true,
      infinite: false,
    });
  }
});

// 見積り・請求ボックスタブ切り替え
$(function(){
  $('.js--tab-trigger').on('click', function() {
    $('#js--tab-trigger').children('.js--tab-trigger').toggleClass('is-tab-active');
    $('#js--tab-trigger').parents('aside').next('main').children('.tab-target').children('.js--tab-target').toggleClass('is-tab-active');
  });
});

// 見積り・請求ボックス未読切り替え
$(function(){
  $('.js--unread-filter-trigger').on('click', function() {
    const activeTab = $('#tab-estimates').attr('class').match(/is-tab-active/) ? 'estimates' : 'complete_estimates'
    window.location.href = $('a.unread-link')[0].href + `&active_tab=${activeTab}`
  });
});

// AI査定履歴タブ切り替え
$(function(){
  $('.js--tab-trigger-ai-assessment').on('click', function() {
    $('#js--tab-trigger-ai-assessment').children('.js--tab-trigger-ai-assessment').toggleClass('is-tab-active');
    $('#js--tab-trigger-ai-assessment').parents('h3').next('section').children('.js--tab-target').toggleClass('is-tab-active');
  });
});


// // メッセージの画面の高さ
// $(window).on('load resize', function(){
//   var windowHeight = $(window).outerHeight();
//   var headerHeight = $('.header-title').outerHeight();
//   var sendingboxHeight = $('.sending-box').outerHeight();
//   var textareaHeight = windowHeight - (headerHeight + sendingboxHeight );
//   if ( $(".sending-box").length ) {
//     var target = $(".sending-box").offset().top;
//   }

//   $('.message-area-box').css({'height' : textareaHeight + 'px'}).animate({scrollTop: target});
// });

// セレクトボックスカラー
$(function(){
  if($('.js--select select').find('option:selected').hasClass('js--select-first')) {
    $(this).css({'color': '#9a9a9a'});
  }
  $('.js--select  select').on('change', function(){
    if($(this).find('option:selected').hasClass('js--select-first')) {
      $(this).css({'color': '#9a9a9a'});
    } else {
      $(this).css({'color': '#555'});
    }
  });
});

$(function(){
  $('input,select,textarea', '#new_user').on('change', function() {
    if ($('#new_user')[0].checkValidity()) {
      $('[type=submit]', '#new_user').prop('disabled', false);
    } else {
      $('[type=submit]', '#new_user').prop('disabled', 'disabled');
      $(this)[0].reportValidity();
    }
  })
  $('#new_user').on('submit', function(e) {
    $('[type=submit]', '#new_user').prop('disabled', 'disabled');
    if ($(this)[0].checkValidity()) {
      // Do submit
    } else {
      ('[type=submit]', '#new_user').prop('disabled', false);
      e.preventDefault();
    }
  })
});

$(function(){
  $(document).on('click','.js-modal-open', function(e){
    var target = $(this).data('target');
    var modal = document.getElementById(target);
    $(modal).fadeIn();
    e.preventDefault();
  });
  $(document).on('click','.js-modal-close',function(e){
    $('.js-modal').fadeOut();
    e.preventDefault();
  }); 
});

(function($){

  $(function(){
    if($('#js--equipment-exchange-item')){
      $('#js--equipment-exchange-item').on('click','li',function(){
        let target = $(this);
        if($('input', target).length > 0){
          let checked = $('input', target).prop('checked');
          $('input', target).prop('checked', !checked);
          target.toggleClass('checked');
        }
      });
    }
  });
  $(function(){
    $('#user_article_equipment_location').on('change',function(){
      let location = $(this).val();
      $('#equipment option').each(function(){
        let l = $(this).attr('data');
        $(this).hide();
        if(location === l ){
          $(this).show();
        }else if(l === 'none'){
          $(this).show();
          $(this).prop('selected',true);
        }
      });
    }).change();
    $('#equipment').on('change', function(){
      let equipment = $(this).val();
      $('#fixtures option').each(function(){
        let id = $(this).attr('data');
        $(this).hide();
        $(this).prop('selected',false);
        if(equipment === id ){
          $(this).show();
        }else if(id === 'none'){
          $(this).show();
        }
      });
    }).change();
  });
})(jQuery);

// ローディングアイコン
$(function(){
    $('.btn-message-link').on('click', function() {
        $('body').addClass('loading');
    });
});

// 対象物件の切り替え
$(function(){
  $('#user_article_switching_box_user_article_id').on('change', function() {
    $(this).parents('form').submit()
  })
})

// 見積モーダル
$(function(){
  $('[name="estimate[reply_method]"').on('change', function(){
    $('[name="estimate[reply_method_other]"]').toggle(this.value == 'その他').prop({disabled: (this.value != 'その他')})
  })

  // 添付ファイル
  FilePond.registerPlugin(FilePondPluginImagePreview);
  FilePond.registerPlugin(FilePondPluginFileValidateSize)
  const filePondInput = document.querySelector('input[class="estimate-filepond"]');
  FilePond.create(filePondInput, {
    storeAsFile: true,
    maxFiles: 5,
    labelIdle: '画像添付（最大5ファイル・1ファイル5MBまで）',
    // FilePondPluginImagePreview
    imagePreviewHeight: 100,
    // FilePondPluginFileValidateSize
    maxFileSize: '5MB',
    labelMaxFileSizeExceeded: 'ファイルサイズがオーバーしています',
    labelMaxFileSize: '最大5MB',
    onaddfilestart: (file) => {
      $('.filepond-onwarning').empty();
    },
    onwarning: (error) => { 
      if (error['body'] == 'Max files') {
        $('.filepond-onwarning').text('添付は5ファイルまで可能です')
      }
    }
  })

});

// AI査定の物件選択
$(function(){
  $('#article-select').on('change', function() {
    const selectedOption = $(this).find(':selected');
    const selectedArticleId = selectedOption.val();
    $('#new-article-link').attr('href', '/users/ai_assessments/new?article_id=' + selectedArticleId);
  });
});


// AI査定類似事例の表示切り替え
$(function () {
  $("#similar-listing button").on("click", function () {
    const classname = $(".expandmore").attr("class");
    if (classname.includes("less")) {
      $(".expandmore").removeClass("less");
      $(".expandless").addClass("less");

      $("#similar-listing ul").show();
    } else {
      $(".expandmore").addClass("less");
      $(".expandless").removeClass("less");

      $("#similar-listing ul").hide();
    }
  });
});

// 詳細査定誘導モーダル
if ($('body').hasClass('ai_assessment-complete')) {
  var flag = false;
  $('.container').on('scroll', function(){
    var docHeight = $('.container main').innerHeight(), //ドキュメントの高さ
        windowHeight = $(window).innerHeight(), //ウィンドウの高さ
        HeaderHeight = $('header').innerHeight(),
        FooterHeight = $('footer.global-nav').innerHeight(),
        pageBottom = docHeight - (windowHeight - HeaderHeight - FooterHeight) - 1; //ドキュメントの高さ - ウィンドウの高さ
    if (pageBottom <= $('.container').scrollTop()) {
      if (!flag) {
        flag = true;
        $('#detailed-assessment-modal').delay(2500).fadeIn();
      }
    }
  });
}

// GA Events
$(function(){
  if ( !window.gtag ) { return }
  $('form[action="/users/sign_in"]', document).on('submit',function(){
    gtag('event', 'login')
  })
})
